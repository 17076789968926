<template>
  <div class="ps-form__content">
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="thumb" data-vertical="true" v-if="product">
          <div class="item" v-for="image in product.images" :key="image.id">
            <img :src="`${image.path}`" alt="Product Image" class="thumb" />
          </div>
          <div
            class="ps-product__variants"
            data-item="4"
            data-md="4"
            data-sm="4"
            data-arrow="false"
          >
            <div class="item" v-for="image in product.images" :key="image.id">
              <img :src="`${image.path}`" alt="Product Image" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Code<sup>*</sup> </label>
          <div class="form-group__content">
            <input
              class="form-control"
              type="text"
              v-model="form.code"
              placeholder=""
            />
            <small v-if="errors && errors.code" class="phone_error text-danger">
              {{ errors.code[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Name<sup>*</sup> </label>
          <div class="form-group__content">
            <input
              class="form-control"
              type="text"
              v-model="form.name"
              placeholder=""
            />
            <small v-if="errors && errors.name" class="phone_error text-danger">
              {{ errors.name[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Description<sup>*</sup> </label>
          <div class="form-group__content">
            <input
              class="form-control"
              v-model="form.description"
              type="text"
            />
            <small v-if="errors && errors.description" class="text-danger">
              {{ errors.description[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Category<sup>*</sup> </label>
          <div class="form-group__content">
            <select class="form-control" v-model="form.category_id">
              <option
                :value="category.id"
                :key="category.id"
                v-for="category in categories"
                >{{ category.name }}</option
              >
            </select>
            <small v-if="errors && errors.category_id" class="text-danger">
              {{ errors.category_id[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>In Stock<sup>*</sup> </label>
          <div class="form-group__content">
            <input class="form-control" v-model="form.in_stock" type="text" />
            <small v-if="errors && errors.in_stock" class="text-danger">
              {{ errors.in_stock[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Status<sup>*</sup> </label>
          <div class="form-group__content">
            <select class="form-control" v-model="form.status">
              <option value="1">Pending</option>
              <option value="2">Active</option>
            </select>
            <small v-if="errors && errors.status" class="text-danger">
              {{ errors.status[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Available on login<sup>*</sup> </label>
          <div class="form-group__content ">
            <input
              type="checkbox"
              class="form-check"
              v-model="form.is_available_on_login"
            />
            <small
              v-if="errors && errors.is_available_on_login"
              class="text-danger"
            >
              {{ errors.is_available_on_login[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>Price<sup>*</sup> </label>
          <div class="form-group__content">
            <input class="form-control" v-model="form.price" type="text" />
            <small v-if="errors && errors.price" class="text-danger">
              {{ errors.price[0] }}
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <button class="ps-btn" @click.prevent="onSubmit()">
        {{ isLoading ? "Submitting..." : "Submit" }}
        <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
        <span
          v-if="isLoading"
          class="spinner-border text-light"
          role="status"
        ></span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "MerchantProductForm",
  props: {
    productId: String,
  },
  data() {
    return {
      errors: {},
      message: null,
      product: null,
      categories: null,
      form: {
        price: null,
        name: "",
        in_stock: "",
        status: "",
        description: "",
        is_available_on_login: false,
      },
      show: true,
      isLoading: false,
    };
  },
  async created() {
    axios.get("categories").then((response) => {
      this.categories = response.data;
    });

    if (this.productId) {
      axios.get(`products/${this.productId}`).then((response) => {
        this.product = response.data;
        this.form.code = this.product.code;
        this.form.price = this.product.price;
        this.form.name = this.product.name;
        this.form.in_stock = this.product.in_stock;
        this.form.description = this.product.description;
        this.form.status = this.product.status;
        this.form.category_id = this.product.category;
        this.form.is_available_on_login = this.product.is_available_on_login;
      });
    }
  },
  methods: {
    async onSubmit() {
      if (this.productId) {
        await this.updateProduct();
      } else {
        await this.createProduct();
      }
    },
    createProduct() {
      this.isLoading = true;
      axios
        .post("products/create", this.form)
        .then((response) => {
          this.isLoading = false;
          setTimeout(() => {
            this.$router.push({ name: "merchant_products" });
          }, 1000);
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    updateProduct() {
      this.isLoading = true;
      axios
        .put("products/" + this.productId, this.form)
        .then((response) => {
          this.isLoading = false;
          setTimeout(() => {
            this.$router.push({ name: "merchant_products" });
          }, 1000);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
