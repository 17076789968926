<template>
  <MerchantProductForm></MerchantProductForm>
</template>

<script>
import MerchantProductForm from "./MerchantProductForm";
export default {
  name: "Create",
  components: {MerchantProductForm}
}
</script>

<style scoped>

</style>